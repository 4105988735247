import { useEffect, useState } from "react"
import { authAxios } from "../../../helpers/axios"
import SlidePopup from "../../../components/SlidePopup"
import { getDateFormat, getTimeFormat, getValidDateFormat } from "../../../helpers/utils"
import { InfoToast } from "../../../helpers/toasters"
import SmallPopup from "../../../components/SmallPopup"

export default function ViewOrder({onClose,id,getOrders})
{
    const [orderInfo,setOrderInfo] = useState({})

    const [step,setStep] = useState(1)
    const [products,setProducts] = useState([])

    const [selectedRoute,setSelectedRoute] = useState('')
    const [selectedCity,setSelectedCity] = useState('')
    const [cities,setCities] = useState([])
    const [retailers,setRetailers] = useState([])
    const [routes,setRoutes] = useState([])
    const [reps,setReps] = useState([])
    const [distributors,setDistributors] = useState([])
    const [loading,setLoading] = useState(true)
    const [showApproveOrder,setShowApproveOrder] = useState(false)
    const [showCancelOrder,setShowCancelOrder] = useState(false)
    const [submitting,setSubmitting] = useState(false)

    async function getOrderInfo(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders/${id}?populate=true`)
        setOrderInfo(res.data.data)
        setLoading(false)
    }
    useEffect(()=>
    {
        getOrderInfo()
    },[])
    
    const onStepSubmit = (e,nextStep)=>
    {
        try 
        {
            e.preventDefault()
            setStep(nextStep)    
        } catch (error) {
            
        }
    }
    
    const onApprove = async()=>
    {
        try 
        {
            setSubmitting(true)
            await authAxios.put(`${process.env.REACT_APP_BASE_URL}/order-approve`,{id:id})
            await getOrders()
            await getOrderInfo() 
            InfoToast('Order Approved')
            setShowApproveOrder(false)
            setSubmitting(false)
        } catch (error) {
            console.log(error)
            setSubmitting(false)
            setShowApproveOrder(false)
            InfoToast('Error approving order')
        }
    }
    
    const onCancel = async()=>
    {
        try 
        {
            setSubmitting(true)
            await authAxios.post(`${process.env.REACT_APP_BASE_URL}/orders/cancel`,{id:id})
            await getOrders()
            await getOrderInfo() 
            InfoToast('Order Cancelled')
            setShowCancelOrder(false)
        } catch (error) {
            console.log(error)
            setSubmitting(false)
            setShowCancelOrder(false)
            InfoToast('Error cancelling order')
        }
    }

    
    useEffect(()=>
    {
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedCity}`)
            setRoutes(res.data.data)
        }
        async function getSalesRep(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative?repCity=${selectedCity}`)
            setReps(res.data.data)
        }
        if(selectedCity !== '') getRoutes()
        if(selectedCity !== '') getSalesRep()
    },[selectedCity])

    useEffect(()=>
    {
        async function getRetailers(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/retailer?retailerRoute=${selectedCity}`)
            setRetailers(res.data.data)
        }
        async function getDistributors(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/distributor?distributorRoute=${selectedCity}`)
            setDistributors(res.data.data)
        }
        if(selectedCity !== '') getRetailers()
        if(selectedCity !== '') getDistributors()
    },[selectedRoute])


    useEffect(()=>
    {
        async function getProducts(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product`)
            setProducts(res.data.data)
        }
        getProducts()
    },[])

    
    
    return <><SlidePopup step={step} setStep={setStep} title={'Order Info'} onClose={onClose} formName={'createOrder'}>
        {
            loading ?  <p>Loading</p>: 
            <div className="w-full">
            <div className="grid grid-cols-2 gap-x-2 gap-y-6">
                <div>
                    <label>Distributor</label>
                    <p className="text-sm font-regular">{orderInfo.orderDistributorId.distributorCompanyName}</p>
                    <p className="text-[13px] font-regular text-[#959595]">({orderInfo.orderDistributorId.distributorName})</p>
                </div>
                <div>
                    <label>Retailer</label>
                    <p className="text-sm font-regular">{orderInfo.orderRetailerId.retailerShopName}</p>
                    <p className="text-[13px] font-regular text-[#959595]">({orderInfo.orderRetailerId.retailerName})</p>
                </div>
                <div>
                    <label>Sales Representative</label>
                    <p className="text-sm font-regular">{orderInfo.orderRetailerId.retailerShopName}</p>
                </div>
                <div>
                    <label>Sales Team</label>
                    <p className="text-sm font-regular">ARA Agents</p>
                </div>

                <div>
                    <label>Total Price</label>
                    <p className="text-sm font-regular">Rs.{parseInt(orderInfo.orderAmount)} <span className="text-red-500 text-xs inline-block">(-{orderInfo.orderOfferAmount ? `Rs.${orderInfo.orderOfferAmount}` : ''})</span></p>
                    <p className="text-[12px] font-regular text-[#959595]">(Incl. Rs.{orderInfo.orderTaxAmount} Tax)</p>
                </div>
                <div>
                    <label>Total Rewards</label>
                    <p className="text-sm font-regular">{parseInt(orderInfo.rewardPoints)} Pts</p>
                </div>
                <div>
                    <label>Order Date/Time</label>
                    <p className="text-sm font-regular">{getDateFormat(orderInfo.created_date)} <span className="text-xs text-[#959595]">{getTimeFormat(orderInfo.created_date)}</span></p>
                </div>
                <div>
                    <label>Order Tracking ID</label>
                    <p className="text-sm font-regular">#{orderInfo.orderTrackingId}</p>
                </div>
            </div>

            <div className="border-t border-gray-200 mt-6 pt-6">
                    <label className="mb-4">Ordered Items</label>
                {
                    orderInfo.orderItems.map((item,index)=>
                    {
                        return <div className="flex grid-cols-4 gap-x-2 mb-8">
                            <div className="flex-1 flex ">
                            <div className="mr-2">
                                <img src={'http://kajah.localhost/uploads/168835746620230703061106.png'} className="w-10 h-10 rounded-full"/>
                            </div>
                                <div>
                                <p className="text-sm font-regular">{item.product.productName}</p>
                                <p className="text-xs font-regular text-[#959595]">{item.product.productSku}</p>
                                </div>
                            </div>
                            {/* <div className="flex-1">
                                <p className="text-sm font-regular">Rs.{parseInt(item.itemPrice)}</p>
                                <p className="text-xs font-regular text-[#959595]">Qty:1</p>
                            </div> */}
                            <div className="flex-1">
                                <p className="text-sm font-regular flex items-center">Rs.{parseInt(item.itemTotal - item.itemOfferAmount)} <span className="text-xs text-red-500 ml-1">(Rs.-{item.itemOfferAmount})</span></p>
                                <p className="text-xs font-regular text-[#959595]">Qty:{parseInt(item.itemQty)+parseInt(item.itemFreeQty)}<span className="text-green-600"> {item.itemFreeQty > 0 ? ` (${item.itemFreeQty} Free Incl.)` : ''}</span></p>
                            </div>
                            </div>
                    })
                }
            </div>
            <div className="flex justify-center w-full  bottom-0 left-0  px-8 pb-4">
                {parseInt(orderInfo.orderStatus) === 1 ? <div> <button onClick={()=>setShowApproveOrder(true)} className="btn-md">Approve Order</button></div> : null}
                {parseInt(orderInfo.orderStatus) === 1 ?<div onClick={()=>setShowCancelOrder(true)} className="ml-4"> <button className="btn-md bg-red-600 hover:bg-red-600">Cancel Order</button></div> : null}
                {/* {parseInt(orderInfo.orderStatus) === 1 ?<div className="ml-4"> <button className="btn-md-inverted brand-text">Edit Order</button></div> : null} */}
            </div>
        </div>
        }
        {showApproveOrder ? <ApproveOrder submitting={submitting} onSubmit={onApprove} onClose={setShowApproveOrder}/> : null}
        {showCancelOrder ? <CancelOrder submitting={submitting} onSubmit={onCancel} onClose={setShowCancelOrder}/> : null}
    </SlidePopup>
    </>
}



const ApproveOrder = ({submitting,onClose,onSubmit})=>
{
    return <SmallPopup submitting={submitting} title={'Approve Order'} onSubmit={onSubmit} submitTitle={'Yes, Approve'} onClose={onClose}>
        <div>
            <p className="text-sm">Are you sure you want to approve the order?</p>
        </div>
    </SmallPopup>
}

const CancelOrder = ({onSubmit,onClose,submitting})=>
{
    return <SmallPopup submitting={submitting} title={'Cancel Order'} onClose={onClose} onSubmit={onSubmit} submitTitle={'Yes, Cancel'}>
        <div>
            <p className="text-sm">Are you sure you want to cancel the order?</p>
        </div>
    </SmallPopup>
}


