import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"
import { data } from "../../dashboard/components/PriceData"
import SmallPopup from "../../../components/SmallPopup"

export default function ShowShipped({onClose,edit=false,onSubmit,submitting})
{
    const [manageData,setManageData] = useState({id:edit})
    const [category,setCategory] = useState([])
    const [hsn,setHsn] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'readyToShip';

    return <><SmallPopup title={'Ready to Ship' } submitTitle={'Submit'} submitting={submitting} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,edit)} id={formName} className='w-full'>
            <div className="mb-4">
                <p className="text-sm font-medium ">Are you sure you want to change the status to Shipped?</p>
            </div>
        </form>
    </SmallPopup>
    </>
}