import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { findCountryByCode } from '../../helpers/utils'
import ManageSupplier from './components/ManageTea'
import ManageTea from './components/ManageTea'
import CompleteBlend from './components/CompleteBlend'

export default function BlendedTeaInfo() {

    const {id} = useParams();
    const [gardenInfo,setGardenInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [showDelete,setShowDelete] = useState(false)
    const [activeTab,setActiveTab] = useState('orders')
    const [showComplete,setShowComplete] = useState(false)
    const navigate=  useNavigate()
    const [showAddProduct,setShowAddProduct] = useState({status:false,edit:false})

    const RightContent = ()=>
    {
        return gardenInfo.status === 1 || gardenInfo.status === '1' ? <button type='button' className='btn-md text-xs font-semibold' onClick={()=>setShowComplete(true)}>Complete Blend</button> : null
    }

    async function getGardenInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/blend/${id}?populate=true`)
            setGardenInfo(res.data)
        } catch (error) {
            toast('Error getting Blend Tea')
        }
    }
    useEffect(()=>
    {
        getGardenInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/blend/${id}`,data); 
            await getGardenInfo()
            setShowEdit(false)
            InfoToast('Garden Updated')
        } catch (error) {
            console.log(error)
            ErrorToast(error.response)
        }
    }

    const onComplete = async(e,blendWeight,warehouseId)=>
        {
            try 
            {
                e.preventDefault()
                let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/blend/complete/${id}`,{blendWeight:blendWeight,warehouseId:warehouseId}); 
                setShowComplete(false)
                await getGardenInfo()
                InfoToast('Blend Completed')
            } catch (error) {
                ErrorToast(error.response.data.error)
            }
        }

  return (
    <>
        <Header parentTitle='Blended Tea' parentLink='./blended-tea' title={`#${gardenInfo.batchNumber} ${gardenInfo.blendName ? `(${gardenInfo.blendName})` : ''}`} RightContent={RightContent}/>

        <div className='flex flex-col flex-1 overflow-scroll bg-white w-full '>
        <div className=' justify-start items-start w-full py-12 px-6'>
        <div className='grid grid-cols-3 gap-6 w-full'>
            <div>
                <label>Total Raw Tea Weight</label>
                <p className='text-sm font-medium'>{gardenInfo?.totalWeight ? gardenInfo?.totalWeight : 'N/A'}</p>
            </div>
            <div>
                <label>Total Raw Tea Bags</label>
                <p className='text-sm font-medium'>{gardenInfo?.bags ? gardenInfo?.bags : 'N/A'}</p>
            </div>
            <div>
                <label>Total Blend Tea Weight</label>
                <p className='text-sm font-medium'>{gardenInfo?.blendWeight ? `${gardenInfo?.blendWeight} Kg` : 'N/A'}</p>
            </div>
            <div>
                <label>Total Blend Tea Remaining</label>
                <p className='text-sm font-medium'>{gardenInfo?.remainingWeight} Kg</p>
            </div>
            <div>
                <label>Status</label>
                <p className='text-sm font-medium'>{gardenInfo?.status === 1 ? 'Created' : gardenInfo.status === 2 ? 'Initiated' : gardenInfo.status === 3 ? 'Blended' : 'Stored'}</p> 
            </div>
            <div>
                <label>Warehouse</label>
                <p className='text-sm font-medium'>{gardenInfo?.warehouseId ? gardenInfo?.warehouse?.name : 'N/A'}</p> 
            </div>
            <div>
                <label>Blend Leftover Cost</label>
                <p className='text-sm font-medium'>Rs.{gardenInfo?.blendLeftoverCost ? parseInt(gardenInfo?.blendLeftoverCost) : 'N/A'}</p> 
            </div>
            <div>
                <label>Blend Material Cost</label>
                <p className='text-sm font-medium'>Rs.{gardenInfo?.blendMaterialCost ? parseInt(gardenInfo?.blendMaterialCost) : 'N/A'}</p> 
            </div>
            <div>
                <label>Total Cost</label>
                <p className='text-sm font-medium'>Rs.{gardenInfo?.blendMaterialCost ? parseInt(gardenInfo?.blendMaterialCost) + parseInt(gardenInfo?.blendLeftoverCost) : 'N/A'}</p> 
            </div>
        </div>
    </div>
    <div>
        <table>
            <thead>
                <tr>
                    <th>Tea Invoice #</th>
                    <th>Garden Name</th>
                    <th>Bag Weight</th>
                    <th>Bags</th>
                    <th>Total Weight</th>
                </tr>
            </thead>
            <tbody>
                {gardenInfo?.blendmaterials?.map((tea,index)=>
                <tr>
                    <td className='text-sm font-medium'>{tea?.blendRawTeaBag?.tea?.invoiceNumber}</td>
                    <td className='text-sm font-medium'>{tea?.blendRawTeaBag?.tea?.garden?.name}</td>
                    <td className='text-sm font-medium'>{tea?.netWeight/tea?.bags}</td>
                    <td className='text-sm font-medium'>{tea?.bags}</td>
                    <td className='text-sm font-medium'>{tea?.netWeight}</td>
                </tr>)}
            </tbody>
        </table>
    </div>
    
    
    {gardenInfo.blendLeftoverMaterial ? <div className='mt-12'>
        <div className='px-6 mb-6'>
        <p className='text-sm font-medium underline'>Blend Leftovers Used</p>
        </div>
        <table>
            <thead>
                <tr>
                    <th>Batch Number #</th>
                    <th>Blend Name</th>
                    <th>Blend's Weight Used</th>
                    <th>Blend's Total Weight</th>
                </tr>
            </thead>
            <tbody>
                {gardenInfo?.blendLeftoverMaterial?.map((tea,index)=>
                <tr>
                    <td className='text-sm font-medium'>{tea?.blendUsed?.batchNumber}</td>
                    <td className='text-sm font-medium'>{tea?.blendUsed?.blendName ? tea?.blendUsed?.blendName : 'N/A'}</td>
                    <td className='text-sm font-medium'>{tea?.totalWeight}</td>
                    <td className='text-sm font-medium'>{tea?.blendUsed?.totalWeight}</td>
                </tr>)}
            </tbody>
        </table>
    </div> : null}
            
        </div>
        { showEdit ? <ManageTea onClose={setShowEdit} onSubmit={onSubmit} edit={true} updateData={gardenInfo}/> : null}
        { showComplete ? <CompleteBlend onClose={setShowComplete} setShow={setShowComplete} onSubmit={onComplete} updateData={gardenInfo}/> : null}
    </>
  )
}
