import React, { useEffect, useState } from 'react'
import Header from '../../components/Header.jsx'
import SearchInput from '../../components/SearchInput.jsx'
import { authAxios } from '../../helpers/axios.js'
import { toast } from 'react-toastify'
import Popup from '../../components/Popup.jsx'
import Input from '../../components/Input.jsx'
import FilterSelect from '../../components/FilterSelect.jsx'
import Select from '../../components/Select.jsx'
import Map from '../../components/Map.jsx'
import { useNavigate } from 'react-router-dom'
import ManageSupplier from './components/ManageWarehouse.jsx'
import { getBase64Image } from '../../helpers/helpers.js'
import DefaultImage from '../../images/image.svg'
import { InfoToast } from '../../helpers/toasters.js'
import RetailerFilter from './components/RetailerFilter.jsx'
import { BRAND_COLOR, LIMIT } from '../../helpers/constants.js'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import EditIcon from '../../images/edit.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import { findCountryByCode } from '../../helpers/utils.js'
import ManageWarehouse from './components/ManageWarehouse.jsx'
import ManageWarehouseSection from './components/ManageSection.jsx'

export default function Warehouse() {

    const [searchText,setSearchText] = useState('')
    const [warehouses,setWarehouses] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [showCreateSection,setShowCreateSection] = useState({status:false,edit:null,warehouse:null})
    const navigate = useNavigate()

    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null})
    const [showFilters,setShowFilters] = useState(false)
    const [sort,setSort] = useState('name');
    const [warehouseType,setWarehouseType] = useState('raw')
    const [activeItem,setActiveItem] = useState(null)
    const [teas,setTeas] = useState([])
    
  
    async function getWarehouses(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/warehouse?populate=true&type=${warehouseType}`)
            setWarehouses(res.data)
            // if(res.data.data) 
            // {
            //     setWarehouses(res.data.data)
            //     setCount(res.data.count)
            // }
        } catch (error) {
            console.log(error)
            toast('Error getting warehouses')
        }
    }

    useEffect(()=>
    {
        getWarehouses()
    },[warehouseType])

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Create Warehouse</button>
        </div>
    }

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res;
            if(showCreate.edit)
            {
                InfoToast('Warehouse Updated')
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/warehouse/${showCreate.edit.id}`,data); 
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/warehouse`,data)
                InfoToast('Warehouse Created')
            }
            if(res.data)
            {
                await getWarehouses()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating warehouse')
        } catch (error) {
            console.log(error)
            toast(error.response)
        }
    }

    const onSectionSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res;
            if(showCreate.edit)
            {
                InfoToast('Section Updated')
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/section/${showCreate.edit.id}`,{name:data.name,warehouseId:data.warehouse.id}); 
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/section`,{name:data.name,warehouseId:data.warehouse.id})
                InfoToast('Section Created')
            }
            if(res.data)
            {
                await getWarehouses()
                setShowCreateSection({status:false,edit:null,warehouse:null})
            }
            else toast('error updating section')
        } catch (error) {
            console.log(error)
            toast(error.response)
        }
    }

    useEffect(()=>
    {
        async function getSectionInfo()
        {
            try 
            {
                if(activeItem?.status && activeItem?.type === 'section')
                {
                    let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/section/tea/${activeItem?.id}`)
                    setTeas(res.data)
                }
                else
                {
                    let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/warehouse/blends/${activeItem?.id}`)
                    setTeas(res.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getSectionInfo()
    },[activeItem])

  return (
    <>
        <Header title={'Warehouse Management'} RightContent={RightContent}/>
        {/* <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
        <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Garden'} label={'Search Garden'} />
            <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
                </div>
                <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.status) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div> */}
        <div className='grid grid-cols-12 bg-white w-full overflow-scroll'>
            <div className='col-span-4 py-4 px-4 border-r border-r-gray-100 h-full'>
                <div className='mb-4'>
                    <h3 className='text-sm font-semibold'>Warehouses</h3>
                </div>
                <div className='flex justify-center mb-6'>
                    <div  className='flex items-center justify-between bg-[#efefef] rounded-md overflow-hidden  border border-gray-200'>
                        <div onClick={()=>setWarehouseType('raw')} className={`flex items-center justify-center  rounded-md px-2 py-1 shadow-sm rounded-r-none  cursor-pointer ${warehouseType === 'raw' ? 'bg-[#29C08B] text-white hover:bg-[#35705b]' : ''}`}>
                            <p className={`text-xs p-1 font-medium ${warehouseType === 'raw' ? 'text-white' : 'text-black'}`}>Raw Tea</p>
                        </div>
                        <div onClick={()=>setWarehouseType('blend')} className={`flex items-center justify-center rounded-md px-2 py-1 shadow-sm rounded-l-none  cursor-pointer ${warehouseType === 'blend' ? 'bg-[#29C08B] text-white hover:bg-[#35705b]' : ''}`}>
                            <p className={`text-xs p-1 font-medium ${warehouseType === 'blend' ? 'text-white' : 'text-black'}`}>Blended Tea</p>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        warehouseType === 'raw' ? warehouses?.map((warehouse,index)=>
                        {
                            return <div key={index} className='bg-[#fafafa] rounded-md overflow-hidden w-full min-h-[180px] border border-gray-200 mb-4'>
                                <div className={`flex items-center p-3 py-3 bg-[${BRAND_COLOR}] justify-between`}>
                                    <div className='flex items-center'>
                                        <div>
                                            <p className='text-sm mr-2 font-semibold text-white'>{warehouse.name}</p>
                                            <p className='text-xs mr-2 font-semibold text-white'>Raw Tea Warehouse</p>
                                        </div>
                                        <div className='cursor-pointer' onClick={()=>setShowCreate({status:true,edit:warehouse})}>
                                            <img src={EditIcon}  className='w-[12px] h-[12px]'/>
                                        </div>
                                    </div>
                                        <p onClick={()=>setShowCreateSection({status:true,edit:null,warehouse:warehouse})} className='text-xs px-3 py-1 bg-white rounded-sm font-medium text-center text-black cursor-pointer hover:bg-[#fafafa]'>Add Section</p>
                                </div>
                                <div className='p-3 grid grid-cols-3 gap-3'>
                                    {
                                        warehouse.sections?.map((section,index)=>
                                        {
                                            return <div onClick={()=>setActiveItem({status:true,type:'section',id:section.id})} key={index} className={`w-full h-[40px] rounded-md border border-[#29C08B] flex items-center justify-center ${activeItem?.id === section.id ? 'bg-[#29C08B] text-white hover:bg-[#4da485]' : ''}`}>
                                                <p className={`text-sm mr-2 font-medium text-center ${activeItem?.id === section.id ? 'text-white' : 'text-black'}`}>{section.name}</p>
                                            </div>
                                        })
                                    }
                                </div>
                            </div> 
                        }) : warehouses.map((warehouse,index)=>
                            {
                                return <div key={index} className='bg-[#fafafa] rounded-md overflow-hidden w-full border border-gray-200 mb-4'>
                                    <div onClick={()=>setActiveItem({status:true,type:'warehouse',id:warehouse.id})} className={`flex items-center p-3 py-3 cursor-pointer ${activeItem?.id === warehouse.id ? 'bg-[#29C08B] text-white hover:bg-[#4da485]' : ''} justify-between`}>
                                        <div className='flex items-center justify-between w-full'>
                                            <div>
                                                <p className={`text-sm mr-2 font-semibold ${activeItem?.id === warehouse.id ? 'text-white' : 'text-black'}`}>{warehouse.name}</p>
                                                <p className={`text-xs mr-2 font-medium ${activeItem?.id === warehouse.id ? 'text-white' : 'text-black'}`}>Blended Tea Warehouse</p>
                                            </div>
                                            <div className='cursor-pointer' onClick={()=>setShowCreate({status:true,edit:warehouse})}>
                                                <img src={EditIcon}  className='w-[12px] h-[12px]'/>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            })
                    }
                </div>
            </div>

            <div className='col-span-8'>
                {/* <p className='text-sm font-medium'>{activeItem?.status && activeItem?.type === 'section' ? activeItem?.name : 'Select a section'}</p> */}
                <div className='flex flex-1 overflow-scroll bg-white w-full'>
                    {activeItem?.type === 'section' ? <table className='w-full'>
                        <thead>
                            <tr>
                                <th>Invoice #</th>
                                <th>Garden</th>
                                <th>Bags</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teas?.map((tea,index)=>
                            {
                                return <tr key={index} onClick={()=>navigate(`/raw-tea/${tea.id}`)} className='cursor-pointer hover:bg-[#fafafa]'>
                                    <td className='text-sm font-medium'>{tea.invoiceNumber}</td>
                                    <td className='text-sm font-medium'>{tea.garden?.name}</td>
                                    <td className='text-sm font-medium'>{tea.bags}</td>
                                </tr>
                            })}
                            </tbody>
                        </table> : null}

                        {
                            activeItem?.type === 'warehouse' ? 
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th>Batch #</th>
                                        <th>Blend Name</th>
                                        <th>Weight</th>
                                        <th>Bags</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teas.map((blend,index)=>
                                    {
                                        return <tr key={index} onClick={()=>navigate(`/blended-tea/${blend.id}`)} className='cursor-pointer hover:bg-[#fafafa]'>
                                            <td className='text-sm font-medium'>{blend.batchNumber}</td>
                                            <td className='text-sm font-medium'>{blend.blendName}</td>
                                            <td className='text-sm font-medium'>{blend.blendWeight}</td>
                                            <td className='text-sm font-medium'>{blend.bags}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table> : null
                        }
                </div>
            </div>
        </div>
        { showCreate.status ? <ManageWarehouse onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit} updateData={showCreate.edit}/> : null}
        { showCreateSection.status ? <ManageWarehouseSection onSubmit={onSectionSubmit} onClose={setShowCreateSection} warehouse={showCreateSection.warehouse}/> : null}
    </>
  )
}

