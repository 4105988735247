import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import TabGroup from '../../components/TabGroup'
import Cities from './components/Cities'
import Grade from './components/Grade'

export default function Settings() 
{
    const [showManage,setShowManage] = useState({type:null,status:false,edit:null})
    const [activeTab,setActiveTab] = useState('grades')

    
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowManage({type:activeTab,status:true})}>Add {activeTab === 'grades' ? 'Grades' : 'City'}</button>
        </div>
    }

  return (
    <>
    <Header title={'Settings'} RightContent={RightContent} parentTitle='' parentLink='sales-representatives'/>
    <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
        <div className='px-4'>
           <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Grades',value:'grades'},{label:'Cities',value:'cities'}]}/>
        </div>
    </div>
    <div className='bg-white w-full flex-1 flex overflow-scroll'>
        {activeTab === 'cities' ? <Cities setShowManage={setShowManage} showManage={showManage}/> : <Grade setShowManage={setShowManage} showManage={showManage}/>}
    </div>
</>
  )
}








