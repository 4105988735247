import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { COUNTRIES, RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { IoAdd, IoAddCircle, IoRemoveCircle } from "react-icons/io5"

export default function EditProductCommonBom({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({id:updateData.id,quantity:updateData.quantity,shared:updateData.shared})

    const formName = 'editProductCommonBom';
    const [commonBom,setCommonBom] = useState(null)

    async function getCommonBom()
    {
        let res = await authAxios.get(`/common-bom/${updateData.commonBomId}`)
        setCommonBom(res.data)
    }

    useEffect(()=>{
        getCommonBom()
    },[])

    return <><Popup title={'Update Product Common BOM'} submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='w-full'>
            <div className="col-span-4">
                <div className="w-20 h-20 rounded- overflow-hidden mb-12">
                    <img src={`${process.env.REACT_APP_STATIC_URL}/${commonBom?.image}`} alt="common-bom" className="w-full h-full  rounded-xl"/>
                </div>
                </div>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
                <div className="flex items-center relative mb-4">
                    <div className="grid grid-cols-7 w-full mr-10 border border-gray-200 bg-[#fcfcfc] rounded-[4px] overflow-hidden">
                        <div className="col-span-3">
                            <input disabled type="text" placeholder="Material Name" className="w-full py-2 px-4 text-[13px] font-medium border-r border-r-gray-300" value={commonBom?.name}/>
                        </div>
                        <div className="col-span-2">
                            <select className="w-full py-2 px-4 text-[13px] font-medium focus:shadow-none focus:ring-0 border-0 border-r border-r-gray-200 rounded-none" value={manageData.shared} onChange={(e)=>setManageData(prev=>({...prev,shared:e.target.value}))}>
                                <option value={0}>Need Qty of</option>
                                <option value={1}>Shared With</option>
                                <option value={2}>Shared Weight</option>
                                </select>
                        </div>
                        <div className="col-span-1 border-r border-r-gray-200">
                            <input type="text" placeholder="Qty." className="w-full py-2 px-4 text-[13px] font-medium" value={manageData.quantity} onChange={(e)=>setManageData(prev=>({...prev,quantity:e.target.value}))}/>
                        </div>
                        <div className="col-span-1">
                            <input disabled type="text" placeholder="Rate" className="w-full py-2 px-4 text-[13px] font-medium" value={commonBom?.rate}/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </Popup>
    </>
}
