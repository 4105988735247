import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import CloseIcon from "../../../images/close.svg"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import StepTwo from "./StepTwo"
import StepOne from "./StepOne"
import StepThree from "./StepThree"
import SlidePopup from "../../../components/SlidePopup"
import FilterSelect from "../../../components/FilterSelect"

export default function FilterOrder({filters,onSubmit,onClose,clearFilter})
{
    const [localFilters,setLocalFilters] = useState({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
    const [cities,setCities] = useState([])
    const [retailers,setRetailers] = useState([])
    const [routes,setRoutes] = useState([])
    const [reps,setReps] = useState([])
    const [distributors,setDistributors] = useState([])
    const [loading,setLoading] = useState(true)
    const [suppliers,setSuppliers] = useState([])


    useEffect(()=>
    {
        async function getSuppliers(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/supplier?limit=100`)
            setSuppliers(res.data.rows)
            setLoading(false)
        }
        getSuppliers()
        setLocalFilters(filters)
    },[])





    
    
    return <><SlidePopup title={'Order Filters'} formName={'createOrder'} onClose={onClose}>
        {
            loading ?  <p>Loading</p>: 
            <div className="w-full">
            <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                {/* <div>
                    <label>Suppliers</label>
                    <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select supplier'} options={suppliers} value={localFilters.supplierId} customLabel={'name'} customValue={'id'} setValue={(value)=>setLocalFilters(data=>({...data,supplierId:value}))} padding={false}/>
                </div>
                </div> */}
                
                <div>
                    <label>Status</label>
                    <div className='h-[100%]'>
                    <select placeholder="Select Status" defaultValue={localFilters.status} className={`min-w-[120px] px-2 pr-0`} value={localFilters.status} onChange={(e)=>setLocalFilters(data=>({...data,status:e.target.value}))}>
                        <option value=''>All</option>
                        <option value={'created'}>Created</option>
                        <option value={'partial'}>Partial</option>
                        <option value={'received'}>Received</option>
                    </select>
                </div>
                </div>
                <div>
                    <label>Start Date</label>
                    <div className='h-[100%]'>
                    <Input type='date' placeholder={'Select Start Date'} value={localFilters.startDate} setValue={(value)=>setLocalFilters(data=>({...data,startDate:value}))}/>
                </div>
                </div>
                <div>
                    <label>End Date</label>
                    <div className='h-[100%]'>
                    <Input type='date' placeholder={'Select End Date'} value={localFilters.endDate} setValue={(value)=>setLocalFilters(data=>({...data,endDate:value}))}/>
                </div>
                </div>
                <div className='flex justify-end mt-4  w-full col-span-2'>
                    <button type='button' className='btn-md-disabled' onClick={clearFilter}>Clear Filters</button>
                    <button type='submit' className='ml-4 btn-md' onClick={()=>onSubmit(localFilters)}>Filter Orders</button>
                </div>
            </div>
        </div>
        }
    </SlidePopup>
    </>
}



