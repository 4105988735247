import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import CropPopup from "../../../components/CropPopup"
import { IoAdd, IoAddCircle, IoRemoveCircle } from "react-icons/io5"
import { authAxios } from "../../../helpers/axios"
import { ErrorToast } from "../../../helpers/toasters"
import Select from "../../../components/Select"
import Loader from "../../../components/Loader"
import ModalImage from "react-modal-image"

export default function CreateCommonBomPurchase({onClose,edit=false,onSubmit,updateData,submitting})
{
    // {bomId:'',bomName:'',productId:'',quantity:''}
    const [manageData,setManageData] = useState(!edit ? {name:'',sku:'',weight:'',purchaseProducts:[{commonBomId:'',supplierId:'',quantity:''}]} : {...updateData,commonBomId:updateData.id,quantity:updateData.quantity,supplierId:updateData.supplierId})
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [suppliers,setSuppliers] = useState([])
    const [commonBoms,setCommonBoms] = useState([])
    const [loading,setLoading] = useState(true)



    
    async function getSuppliers(){

        try 
        {
            let query = `limit=${150}&offset=${0}&populate=true`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/supplier?${query}`)
            // console.log('salse',res.data.data)
            setSuppliers(res.data.data)
            // setCount(res.data.count)
            // setSummary(res.data.summary)
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    } 

    async function getCommonBoms(){

        try 
        {
            let query = `limit=${150}&offset=${0}&populate=true`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/common-bom?${query}`)
            // console.log('salse',res.data.data)
            setCommonBoms(res.data.rows)
            setLoading(false)
            // setCount(res.data.count)
            // setSummary(res.data.summary)
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    } 



    useEffect(()=>
    {
        getSuppliers()
        getCommonBoms()
    },[])
   


    const addCommonBom = () => {
        setManageData((prev) => {
            let newData = {...prev};
            newData.purchaseProducts = [...prev.purchaseProducts, {supplierId:'',commonBomId:'',quantity:''}];
            return newData;
        });
    }

    const removeCommonBom = (index) => {
        setManageData(prev => {
            let newData = {...prev};
            newData.purchaseProducts = prev.purchaseProducts.filter((_, i) => i !== index);
            return newData;
        })
    };

    const setItem = (index, key, value) => {
        setManageData((prev) => {
            let newData = {...prev};
            newData.purchaseProducts[index][key] = value;
            return newData;
        });
    }

    return <><Popup size="xl" submitting={submitting} title={edit ? 'Update Common BOM Purchase Order' : 'Create Common BOM Purchase Order'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        {!loading ? <form onSubmit={(e)=>onSubmit(e,manageData)} id={formName} className='w-full'>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">

            <div >
                {
                    manageData.purchaseProducts.map((item,index)=>{
                        return <CommonBomItem commonBoms={commonBoms} length={manageData.purchaseProducts.length} key={index} item={item} index={index} purchaseProducts={manageData.purchaseProducts} addProduct={addCommonBom} setItem={setItem} suppliers={suppliers} removeBom={removeCommonBom}/>
                    })
                }
            </div>
            </div>
        </form> : <Loader/>}
    </Popup>
    </>
}




const CommonBomItem = ({item, index, purchaseProducts, addProduct, commonBoms, suppliers=[], removeBom, setItem,length}) => {
  return <div className="block items-center relative mb-4 ml-2  ">
    <div className="grid grid-cols-12 w-full mr-10 ">
      <div className="col-span-3"><label>BOM</label></div>
      <div className="col-span-3"><label>Supplier</label></div>
      <div className="col-span-3"><label>In Stock</label></div>
      <div className="col-span-3"><label>Order Qty</label></div>
    </div>

    <div className="flex">
      {/* <div className="image-preview mr-2 ">
        <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.commonBomImage}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.commonBomImage}`} alt={item.commonBomName} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
      </div> */}
      <div className="grid grid-cols-12 w-full mr-10 border border-gray-200 bg-[#fcfcfc] rounded-[4px]">
        <div className="col-span-3">
          <select required type="text" placeholder="Product Name" className="w-full py-2 px-4 text-[13px] font-medium border-0 rounded-none border-r border-r-gray-300 outline-none active:ring-0 bg-transparent " value={item.commonBomId} onChange={(e)=>setItem(index, 'commonBomId', e.target.value)}>
            <option value={''}>Select BOM</option>
            {
              commonBoms.map((item, index) => {
                return <option key={index} value={item.id}>{item.name}</option>
              })
            }    
          </select>
        </div>
        <div className="col-span-3">
          <select required type="text" placeholder="Product Name" className="w-full py-2 px-4 text-[13px] font-medium border-0 rounded-none border-r border-r-gray-300 outline-none active:ring-0 bg-transparent " value={item.supplierId} onChange={(e)=>setItem(index, 'supplierId', e.target.value)}>
            <option value={''}>Select Supplier</option>
            {
              suppliers.map((item, index) => {
                return <option key={index} value={item.id}>{item.name}</option>
              })
            }    
          </select>
        </div>
        {/* <div className="col-span-2 h-full flex items-center px-2">
           <p className="text-[13px] font-medium">{item.commonBomName}</p>
        </div> */}
        <div className="col-span-3">
          <input required type="text" disabled={true} placeholder="Quantity" className="w-full py-2 px-4 text-[13px] font-medium border-r border-r-gray-300" value={(item.stock)}/>
        </div>
        <div className="col-span-3">
          <input required type="text" placeholder="Quantity" className="w-full py-2 px-4 text-[13px] font-medium" value={item.quantity} onChange={(e)=>setItem(index, 'quantity', e.target.value)}/>
        </div>
      </div>
    </div>
    {index+1 >= length ? <div className="ml-2 absolute top-5 right-0">
      <button type="button" onClick={addProduct}><IoAddCircle fontSize={32}/></button>
    </div> :  <div className="ml-2 absolute top-5 right-0"><button type="button" onClick={()=>removeBom(index)}><IoRemoveCircle color="red" fontSize={32}/></button></div>}
  </div>
}
