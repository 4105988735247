import { useEffect, useState } from "react"
import SmallPopup from "../../../components/SmallPopup"
import Popup from "../../../components/Popup"
import Input from "../../../components/Input"

export default function ShippingPopup({onClose,edit=false,onSubmit,orderInfo,submitting})
{
    const [manageData, setManageData] = useState({ id: edit, shippingProducts: [] });
    const [category, setCategory] = useState([]);
    const [hsn, setHsn] = useState([]);
    const [showCrop, setShowCrop] = useState(false);
    const formName = 'updateShipping';

    useEffect(() => {
        if (orderInfo && orderInfo.shippingProducts) {
            const updatedShippingProducts = orderInfo.shippingProducts.map(item => ({
                id: item.id,
                productName: item.product.name,
                productId: item.product.id,
                shippingOrderId: item.shippingOrderId,
                shippedQuantity: item.shippedQuantity,
                shippedCartons: item.shippedCartons,
                quantity: item.quantity,
                cartons: item.cartons,
                totalProducts: item.totalProducts,
                shippingCartons: item.shippingCartons || '',
            }));
            setManageData(prevData => ({ ...prevData, shippingProducts: updatedShippingProducts }));
        }
    }, [orderInfo]);

    const updateShippingQuantity = (index, value) => {
        setManageData(prevData => {
            const updatedShippingProducts = [...prevData.shippingProducts];
            updatedShippingProducts[index].shippingCartons = value;
            return { ...prevData, shippingProducts: updatedShippingProducts };
        });
    };

    return (
        <Popup title={'Update Shipping'} submitTitle={'Submit'} submitting={submitting} onClose={onClose} formName={formName}>
            <form name={formName} onSubmit={(e) => onSubmit(e, manageData)} id={formName} className='w-full'>
                <div className="grid grid-cols-8 mb-1 ">
                    <div className="items-center px-4 col-span-4">
                        <p className="text-xs text-[#959595] font-medium">Product Name</p>
                    </div>
                    <div className="px-4 items-center col-span-2">
                        <p className="text-xs text-[#959595] font-medium">Total Cartons</p>
                    </div>
                    <div className="w-full col-span-2">
                        <p className="text-xs text-[#959595] font-medium">Enter Cartons</p>
                    </div>
                </div>
                {manageData.shippingProducts.map((item, index) => {
                    return <div className="grid grid-cols-8 border border-gray-300 rounded-md mb-4" key={index}>
                        <div className="py-2 items-center px-4 col-span-4">
                            <p className="text-sm font-medium">{item.productName}</p>
                        </div>
                        <div className="border-l border-gray-300 py-2 px-4 items-center col-span-2">
                            <p className="text-sm font-medium">{item.cartons}</p>
                        </div>
                        <div className="border-l border-gray-300 w-full col-span-2">
                            <input type="text" 
                            className="w-full py-2 px-4 rounded-md"
                                value={item.shippingCartons}
                                onChange={(e) => updateShippingQuantity(index, e.target.value)}
                            />
                        </div>
                    </div>
})}
            </form>
        </Popup>
    );
}