import { useEffect, useState } from "react"
import Popup from "../../../components/Popup"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import Input from "../../../components/Input"

export default function ManageCommonBom({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({id:edit ? updateData.id : null,name:edit ? updateData.name : '',quantity:edit ? updateData.quantity : '',image:edit ? updateData.image : '',rate:edit ? updateData.rate : '',stock:edit ? updateData.stock : ''})
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editCommonBom';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState(null)

    const onImageChange = async(event) => {
        if (event.target.files && event.target.files[0]) {
            setManageData(data=>({...data,image:null}))
            // setShowCrop(true)
            // console.log(URL.createObjectURL(event.target.files[0]))
            // let blobImage = await dataURItoBlob(event.target.files[0])
            // console.log(blobImage)
            setImage(URL.createObjectURL(event.target.files[0]));
            setCroppedImage(event.target.files[0])
            // setImage(event.target.files[0]);
        }
        }



    return <><Popup title={edit?'Edit Common BOM':'Add Common BOM'} submitTitle={edit?'Update':'Add'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData},croppedImage)} id={formName} className='w-full'>
            <div className="col-span-4">
                    <ImageInput image={manageData.image && manageData.image!=='' ? `${manageData.image}` : image} setImage={onImageChange} />
                </div>
                <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
                    <div>
                        <label className="text-[13px] font-medium">BOM Name</label>
                        <Input value={manageData.name} setValue={(value)=>setManageData(prev=>({...prev,name:value}))}/>
                    </div>
                    <div>
                        <label className="text-[13px] font-medium">BOM Rate</label>
                        <Input type="number" value={manageData.rate} setValue={(value)=>setManageData(prev=>({...prev,rate:value}))}/>
                    </div>
                    <div>
                        <label className="text-[13px] font-medium">BOM Stock</label>
                        <Input type="number" value={manageData.stock} setValue={(value)=>setManageData(prev=>({...prev,stock:value}))}/>
                    </div>
            </div>
        </form>
    </Popup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}
