import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { useParams } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { getDateFormat } from '../../helpers/utils'
import Loader from '../../components/Loader'
import UpdateReceiving from './components/UpdateReceiving'
import ModalImage from 'react-modal-image'

export default function PurchaseInfo() {

    const {id} = useParams();
    const [orders,setOrders] = useState([])
    const [orderInfo,setOrderInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [activeTab,setActiveTab] = useState('orders')
    const [exporting,setExporting] = useState(false)
    const [loading,setLoading] = useState(true)
    const [showUpdate,setShowUpdate] = useState({status:false,item:null})
    const [submitting,setSubmitting] = useState(false)

    const RightContent = ()=>
    {
        return <div>
          {/* <button type='button' className='btn-md'>Download Invoice</button> */}
        </div>
    }

    async function getOrderInfo(){

        try 
        {
            let ordersRes = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/purchase/${id}`)
            if(ordersRes.data) setOrderInfo(ordersRes.data)
                setLoading(false)
        } catch (error) {
            ErrorToast('Error getting Purchase Order')
        }
    }
    useEffect(()=>
    {
        getOrderInfo();
    },[])

    const onReceiving = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/purchase/receiving/${id}`,data); 
            await getOrderInfo()
            setShowUpdate({status:false,edit:null})
            setSubmitting(false)
            InfoToast('Receiving Updated')
        } catch (error) {
            setSubmitting(false)
            InfoToast(error.response)
        }
    }



    const exportInvoice = async(supplierId)=>
    {
        try 
        {
            setExporting(true)
            let response = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/purchase/export/${id}?supplierId=${supplierId}`,{},{responseType:'blob'}); 
                    const url = window.URL.createObjectURL(new Blob([response.data]));
    
                    // Create a temporary anchor element
                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.setAttribute('download', `${orderInfo?.orderId}-${supplierId}.pdf`); // Set the filename for download
    
                    document.body.appendChild(anchor);
                    anchor.click();
    
                    document.body.removeChild(anchor);
                    window.URL.revokeObjectURL(url);
                    setExporting(false)
        } catch (error) {
            console.log(error)
            InfoToast(error.response)
        }
    }

  return (
    !loading ? <>
        <Header title={`#${orderInfo?.orderId}`} parentLink='/purchase' parentTitle='Purchase Order Management' RightContent={RightContent}/>
        {/* <div className=' flex justify-between border-b border-[#e3e3e3] bg-white'>
        </div> */}
        <div className='block flex-1 overflow-scroll bg-white w-full py-10'>
        <div className='grid grid-cols-4 gap-6 w-full border-b border-[#e3e3e3] pb-6 px-4 mb-10'>
            <div>
                <label className='uppercase font-medium text-xs tracking-normal'>Order ID</label>
                <p className='text-sm text-black font-medium'>#{orderInfo?.orderId}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-xs tracking-normal'>Sales Order ID</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.salesOrder ? `#${orderInfo?.salesOrder?.orderId}` : 'Not Available'}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-xs tracking-normal'>Sales Order Buyer</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.salesOrder ? `${orderInfo?.salesOrder?.buyer?.name}` : 'Not Available'}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-xs tracking-normal'>ordered Date</label>
                <p className='text-sm text-black font-medium'>{getDateFormat(orderInfo.orderDate)}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-xs tracking-normal'>Expected Delivery Date</label>
                <p className='text-sm text-black font-medium'>{getDateFormat(orderInfo?.expectedDeliveryDate)}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-xs tracking-normal'>Buyer Name</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.salesOrderId ? orderInfo?.buyer?.name : 'Not Available'}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-xs tracking-normal'>Contact Person</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.salesOrderId ? orderInfo?.buyer?.contactPerson : 'Not Available'}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-xs tracking-normal'>Contact Number</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.salesOrderId ? orderInfo?.buyer?.contactNumber : 'Not Available'}</p>
            </div>

        </div>
       
        <div className='grid grid-cols-4 gap-6 w-full border-b border-[#e3e3e3] pb-6 px-4 mb-10'>
            
            {
                orderInfo.suppliers.map((item,index)=>
                {
                    return <div key={index}>
                    <label className='uppercase font-medium text-xs tracking-normal'>{item.name} Invoice:</label>
                    <p className={`text-sm font-medium underline ${exporting ? 'text-gray-600' : 'text-blue-800'}`} onClick={exporting ? null : ()=>exportInvoice(item.id)}>Download</p>
                </div>
                })
            }

        </div>

        <div className='bg-white w-full flex-1 flex overflow-scroll mt-10'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    {/* <td><p className='cursor-pointer flex items-center hover:text-black'>SKU</p></td> */}
                
                    <td><p className='cursor-pointer flex items-center hover:text-black'>BOM Name</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Product Name</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>BOM Type</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Supplier</p></td>

                    {/* <td><p className='cursor-pointer flex items-center hover:text-black'>Weight</p></td> */}
                    {/* <td><p className='cursor-pointer flex items-center hover:text-black'>Progress</p></td> */}

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Total Qty.</p></td>
                    

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Received Qty.</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'></p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        orderInfo.purchasematerials.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize w-full'>
                                <div className="image-preview mr-2 ">
                                        <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.commonBom ? item.commonBom.image : item.bom ? item.bom.image : item.productCommonBom.commonBom.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.commonBom ? item.commonBom.image : item.bom ? item.bom.image : item.productCommonBom.commonBom.image}`} alt={item.commonBom ? item.commonBom.name : item.bom ? item.bom.name : item.productCommonBom.commonBom.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                    </div>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.commonBom ? item.commonBom.name : item.bom ? item.bom.name : item.productCommonBom.commonBom.name}</p>
                                    </div>
                                </td>
                                <td className='capitalize w-full'>
                                    
                                    {!item.commonBom ? <div className='flex items-center'>

                                <div className="image-preview mr-2 ">
                                        <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.commonBom ? item.commonBom.image : item.bom ? item.bom.product.image : item.productCommonBom.product.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.commonBom ? item.commonBom.image : item.bom ? item.bom.product.image : item.productCommonBom.image}`} alt={item.commonBom ? item.commonBom.name : item.bom ? item.bom.product.name : item.productCommonBom.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                    </div>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{ item.commonBom ? item.commonBom.name : item.bom ? item.bom.product.name : item.productCommonBom.product.name}</p>
                                        <p className='text-xs text-[#959595] font-medium my-0'>{item.commonBom ? item.commonBom.sku : item.bom ? item.bom.product.sku : item.productCommonBom.product.sku}</p>
                                    </div>
                                </div> : null}
                                </td>
                                <td>
                                    <p className='text-sm font-medium my-0'>{item.commonBom ? 'Common BOM' : item.bom ? 'BOM' : 'Product BOM'}</p>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.supplier.name}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.quantity}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.receivedQuantity}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowUpdate({status:true,item:item})}>Update Receiving</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
            
        </div>
        { showUpdate.status ? <UpdateReceiving submitting={submitting} onClose={setShowUpdate} onSubmit={onReceiving} edit={showUpdate.item}/> : null}
    </> : <Loader/>
  )
}