import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import CropPopup from "../../../components/CropPopup"
import { IoAdd, IoAddCircle, IoRemoveCircle } from "react-icons/io5"
import { authAxios } from "../../../helpers/axios"
import { ErrorToast } from "../../../helpers/toasters"
import Select from "../../../components/Select"
import SmallPopup from "../../../components/SmallPopup"

export default function DeleteOrder({id,onSubmit,onClose,submitting})
{



    return <><SmallPopup submitting={submitting} size="sm" title={'Delete Order'} submitTitle={'Delete'} onClose={onClose} formName={'deleteOrder'}>
        <form onSubmit={(e)=>onSubmit(e)} id={'deleteOrder'} className='w-full'>
            <div className="col-span-4">
                <p className="text-sm">Are you sure you want to delete this order and its related production order?</p>
            </div>
        </form>
    </SmallPopup>
    </>
}
