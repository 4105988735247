import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageProduct from './components/ManageProduct'
import { base64ToBlob, dataURItoBlob } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import RupeeIcon from '../../images/rupee.svg'
import { LIMIT } from '../../helpers/constants'
import AdjustStock from './components/AdjustStock'
import ModalImage from 'react-modal-image'
import ProductStockFilter from './components/ProductStockFilter'

export default function ProductStocks() {

    const [searchText,setSearchText] = useState('')
    const [products,setProducts] = useState([])
    const [showUpdate,setShowUpdate] = useState(false)
    const navigate = useNavigate()
    const [sort,setSort] = useState('stock');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)
    

    const [showFilters,setShowFilters] = useState(false)
    const [filters,setFilters] = useState({buyerId:null})
    const RightContent = ()=>
    {
        return <div>
          {/* <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Add Product</button> */}
        </div>
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getStocks(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&sort=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                if(filters.buyerId) query+= `&buyerId=${filters.buyerId.id}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product?populate=true${query}`)
                setProducts(res.data.rows)
                setCount(res.data.totalCount)
        } catch (error) {
            ErrorToast('Error getting Stocks')
        }
    }
    useEffect(()=>
    {
        getStocks();
    },[sort,searchText,offset,filters])


    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/product/stock/${showUpdate.item.id}`,data) 
            InfoToast('Product Updated')
            await getStocks()
            setShowUpdate({status:false,item:null})
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }

    const onFilterSubmit = (filters)=>
        {
            try 
            {
                console.log('filters',filters)
                setFilters({...filters})
                setCount(5)
                setOffset(0)
                setShowFilters(false)
            } catch (error) {
                toast('Order Filtered')
            }
        }
    
        const clearFilter = ()=>
        {
            try 
            {
                setFilters({buyerId:null})
                setShowFilters(false)
            } catch (error) {
                toast('Order Filtered')
            }
        }


  return (
    <>
        <Header title={'Finished Product Stock Management'} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
        <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Product'} label={'Search Product'} />
            <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
                </div>
                <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.supplierId) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div>
        <div className='flex flex-1 bg-white w-full overflow-scroll'>
            <table className='table-fixed w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    {/* <tr className='w-full'>
                    <td><p>Name/SKU</p></td>
                    <td><p>Category</p></td>
                    <td><p>Price(In Rs.)</p></td>
                    <td><p>Weight(In Gms)</p></td>
                    <td><p>Reward(In Pts)</p></td>
                    <td><p>Action</p></td>
                </tr> */}
                    <tr className='w-full'>
                    {/* <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>BOM Name{sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td> */}
                    <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Product Name/SKU{sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('buyerName')} className='cursor-pointer flex items-center hover:text-black'>Buyer{sort === 'buyerName' || sort === '-buyerName'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('stock')} className='cursor-pointer flex items-center hover:text-black'>Stock{sort === 'stock' || sort === '-stock'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        products.map((item,index)=>
                        {
                            return <tr>
                                {/* <td className='capitalize'>
                                    <div>
                                        <img src={item.productImage} className='w-[52px] h-[52px] rounded-full'/>
                                    </div>
                                </td> */}
                                {/* <td className='capitalize w-[200px]'>
                                <div className='flex items-center align-middle'>
                                    <div>
                                    </div>
                                        <div>
                                            <p className='text-sm font-medium my-0'>{item.name}</p>
                                            <p className='text-xs font-medium my-0 text-gray-400'>{item.productSku}</p>
                                        </div>
                                    </div>
                                </td> */}
                                <td className='capitalize'>
                                <div className='flex items-center align-middle'>
                                <div className="image-preview mr-2 ">
                                    <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt={item.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                </div>
                                        <div>
                                            <p className='text-sm font-medium my-0'>{item.name}</p>
                                            <p className='text-xs font-medium my-0 text-gray-400'>{item.sku}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex items-center align-middle'>
                                    <div>
                                    </div>
                                        <div>
                                            <p className='text-sm font-medium my-0'>{item.buyer.name}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex items-center align-middle'>
                                    <div>
                                    </div>
                                        <div>
                                            <p className='text-sm font-medium my-0'>{item.stock}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowUpdate({status:true,item:item})}>Adjust Stock</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        {/* { showCreate.status ? <ManageProduct onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null} */}
        { showUpdate.status ? <AdjustStock onClose={setShowUpdate} onSubmit={onSubmit} edit={showUpdate.item}/> : null}
        { showFilters ? <ProductStockFilter clearFilter={clearFilter} onSubmit={onFilterSubmit} filters={filters} setFilters={setFilters}/> : null}
    </>
  )
}

