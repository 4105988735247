import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import CropPopup from "../../../components/CropPopup"
import { IoAdd, IoAddCircle, IoRemoveCircle } from "react-icons/io5"
import { authAxios } from "../../../helpers/axios"
import { ErrorToast } from "../../../helpers/toasters"
import Select from "../../../components/Select"
import Loader from "../../../components/Loader"
import ModalImage from "react-modal-image"

export default function AddBomToProduct({submitLoading,onClose,onSubmit})
{
    // {bomId:'',bomName:'',productId:'',quantity:''}
    const [manageData,setManageData] = useState({bomId:'',bomName:'',productId:'',quantity:'',shared:false,type:'unique'})
    const [cities,setCities] = useState([])
    const [routes,setRoutes] = useState([])
    const [distributors,setDistributors] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()
    const [products,setProducts] = useState([])
    const [buyers,setBuyers] = useState([])
    const [salesOrders,setSalesOrders] = useState([])
    const [salesOrderInfo,setSalesOrderInfo] = useState({})
    const [suppliers,setSuppliers] = useState([])
    const [boms,setBoms] = useState([])
    const [commonBoms,setCommonBoms] = useState([])
    const [orderProducts,setOrderProducts] = useState([])
    const [loading,setLoading] = useState(false)
    const [selectedCommonBom,setSelectedCommonBom] = useState({})


    async function getCommonBoms(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/common-bom?populate=true&limit=100`)
            setCommonBoms(res.data.rows)
        } catch (error) {
            ErrorToast(error.response.error.name)
        }
    } 
    
    async function getSuppliers(){

        try 
        {
            let query = `limit=${150}&offset=${0}&populate=true`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/supplier?${query}`)
            // console.log('salse',res.data.data)
            setSuppliers(res.data.data)
            // setCount(res.data.count)
            // setSummary(res.data.summary)
        } catch (error) {
            ErrorToast(error.response.error)
        }
    } 

    useEffect(()=>
    {
        if(manageData.type == 'unique')
        {
            setManageData({bomName:'',rate:'',quantity:'',shared:false,type:'unique'})
        }
        else
        {
            setManageData({commonBomId:'',quantity:'',shared:false,type:'common'})
        }
    },[manageData.type])


    useEffect(()=>
        {
            getCommonBoms()
        getSuppliers()
    },[])
   

    const getSelectedCommonBom = ()=>{
        let selected = commonBoms.find(it=>it.id === manageData.commonBomId)
        setSelectedCommonBom(selected)
    }
    useEffect(()=>{
        getCommonBoms()
    },[])
    
    useEffect(()=>{
        if(manageData.commonBomId && manageData.type == 'common') getSelectedCommonBom()
    },[manageData.commonBomId])


    return <><Popup size="sm" title={'Add BOM to Product'} submitTitle={'Add BOM to Product'} onClose={onClose} submitting={submitLoading} formName={formName}>
        {!loading ? <form onSubmit={(e)=>onSubmit(e,manageData)} id={formName} className='w-full'>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
                <div className="col-span-1">
                    <label>BOM Type</label>
                    <Select options={[{name:'Product BOM',value:'unique'},{name:'Common BOM',value:'common'}]} customLabel={'name'} customValue={'value'} placeholder={'Select BOM Type'} value={manageData.type} setValue={(value)=>setManageData(data=>({...data,type:value}))}/>
                </div>
                <div></div>
                {
                    manageData.type == 'unique' ? <>
                    <div className="col-span-1">
                        <label>BOM Name</label>
                        <Input type='text' placeholder={'Enter BOM Name'} value={manageData.bomName} setValue={(value)=>setManageData(data=>({...data,bomName:value}))}/>
                    </div>
                    <div className="col-span-1">
                        <label>BOM Sharing Type</label>
                        <Select options={[{name:'Need Qty of',value:0},{name:'Shared with',value:1},{name:'Shared weight',value:2}]} customLabel={'name'} customValue={'value'} placeholder={'Select BOM Sharing Type'} value={manageData.shared} setValue={(value)=>setManageData(data=>({...data,shared:value}))}/>
                    </div>
                    <div className="col-span-1">
                        <label>Quantity</label>
                        <Input type='number' placeholder={'Enter Quantity'} value={manageData.quantity} setValue={(value)=>setManageData(data=>({...data,quantity:value}))}/>
                    </div>
                    <div className="col-span-1">
                        <label>Rate</label>
                        <Input type='number' placeholder={'Enter Rate'} value={manageData.rate} setValue={(value)=>setManageData(data=>({...data,rate:value}))}/>
                    </div>
                    </> : null
                }
                {
                    manageData.type == 'common' ? <>
                    <div className="col-span-1">
                        <label>BOM Name</label>
                        <Select options={commonBoms} customLabel={'name'} customValue={'id'} placeholder={'Select BOM'} value={manageData.bomId} setValue={(value)=>setManageData(data=>({...data,commonBomId:value}))}/>
                    </div>
                    <div className="col-span-1">
                        <label>BOM Sharing Type</label>
                        <Select options={[{name:'Need Qty of',value:0},{name:'Shared with',value:1},{name:'Shared weight',value:2}]} customLabel={'name'} customValue={'value'} placeholder={'Select BOM Sharing Type'} value={manageData.shared} setValue={(value)=>setManageData(data=>({...data,shared:value}))}/>
                    </div>
                    <div className="col-span-1">
                        <label>Quantity</label>
                        <Input type='number' placeholder={'Enter Quantity'} value={manageData.quantity} setValue={(value)=>setManageData(data=>({...data,quantity:value}))}/>
                    </div>
                    <div className="col-span-1">
                        <label>Rate</label>
                        <Input type='number' disabled={manageData.shared} placeholder={'Enter Rate'} value={selectedCommonBom.rate * manageData.quantity}/>
                    </div>
                    </> : null
                }
            </div>
            </div>
        </form> : <Loader/>}
    </Popup>
    </>
}

