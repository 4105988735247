import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getDateFormat } from '../../../helpers/utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      grid: {
        display: false,
      },
    },
    x: {
        grid: {
          display: false,
        },
      }
  },
  plugins: {
    legend: {
      position: 'top',
      display:false
    },
    style:{
        // border
    }
  },
};

export function UpdateChart({data,type='production'}) {
  const [labels, setLabels] = useState([]);
  const [items, setItems] = useState([]);
  
  useEffect(() => {
    const newLabels = [];
    const newItems = [];
    
    data.forEach((item) => {
      newLabels.push(item.date ? getDateFormat(item.date) : 'Others');
      newItems.push(item.quantity);
    });

    setLabels(newLabels);
    setItems(newItems);
  }, [data]);

  const info = {
    labels,
    datasets: [
      {
        label: 'Quantity',
        data: items,
        backgroundColor: type=='production' ? '#8E2DE2' : type ==='purchase' ? '#ffc08b' : '#8E2DE2',
        borderRadius: 4,
      }
    ],
  };

  return <div className='bg-white border border-gray-200 overflow-hidden rounded-md w-full h-[280px] p-4'>
    <p className='text-sm font-medium tracking-tight mb-4'>{type=='production' ? 'Production Analytics' : type=='purchase' ? 'Purchase Analytics' : 'Shipping Analytics'}</p>
    <div className='w-full h-full'>
      <Bar options={options} data={info} />
    </div>
  </div>;
}
