import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import CloseIcon from "../../../images/close.svg"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import SlidePopup from "../../../components/SlidePopup"
import FilterSelect from "../../../components/FilterSelect"

export default function FilterBlendTea({filters,onSubmit,onClose,clearFilter})
{
    const [localFilters,setLocalFilters] = useState({startDate:null,endDate:null})
    const [loading,setLoading] = useState(false)


    useEffect(()=>
    {
        setLocalFilters(filters)
    },[])





    
    return <><SlidePopup title={'Blend Tea Filters'} formName={'filterBlendTea'} onClose={onClose}>
        {
            loading ?  <p>Loading</p>: 
            <div className="w-full">
            <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                <div>
                    <label>Start Date</label>
                    <div className='h-[100%]'>
                    <Input type='date' placeholder={'Select Start Date'} value={localFilters.startDate} setValue={(value)=>setLocalFilters(data=>({...data,startDate:value}))}/>
                </div>
                </div>
                <div>
                    <label>End Date</label>
                    <div className='h-[100%]'>
                    <Input type='date' placeholder={'Select End Date'} value={localFilters.endDate} setValue={(value)=>setLocalFilters(data=>({...data,endDate:value}))}/>
                </div>
                </div>
                <div className='flex justify-end mt-4  w-full col-span-2'>
                    <button type='button' className='btn-md-disabled' onClick={clearFilter}>Clear Filters</button>
                    <button type='submit' className='ml-4 btn-md' onClick={()=>onSubmit(localFilters)}>Filter Orders</button>
                </div>
            </div>
        </div>
        }
    </SlidePopup>
    </>
}



