import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageRep from './components/ManageRep'
import RepFilter from './components/RepFilter'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import { LIMIT } from '../../helpers/constants'
import { Tooltip } from 'react-tooltip'
import ManageUser from './components/ManageUser'
import { ErrorToast } from '../../helpers/toasters'

export default function Users() {

    const [searchText,setSearchText] = useState('')
    const [reps,setReps] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()

    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [sort,setSort] = useState('name')
    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null,teamId:null})
    const [showFilters,setShowFilters] = useState(false)
    const [submitting,setSubmitting] = useState(false)

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Add User</button>
        </div>
    }

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getUsers(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&sort=${sort}`;
            if(searchText) query+= `&search=${searchText}`;
            if(filters.cityId) query+=`&cityId=${filters.cityId.id}`
            if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
            if(filters.status) query+=`&status=${filters.status}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?populate=true${query}`)
            // console.log('salse',res.data.data)
                setReps(res.data.users)
                setCount(res.data.totalCount)
        } catch (error) {
            toast('Error getting sales representatives')
        }
    }
    useEffect(()=>
    {
        getUsers();
    },[filters,searchText,sort,offset])


    useEffect(()=>
    {
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedFilters.city}`)
            if(res.data.data)  setRouteFilters(res.data.data)
        }
        if(selectedFilters.city !== '') getRoutes()
        else {
            setRouteFilters([]);
            setSelectedFilters(data=>({...data,route:''}))
        }
    },[selectedFilters.city])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log(data)
            // let base64Image = await getBase64Image(data.retailerShopImage)
            setSubmitting(true)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/user`,{...data}) 
                await getUsers()
                toast.info('User Added')
                setSubmitting(false)
                setShowManage({edit:null,status:false})
        } catch (error) {
            setSubmitting(false)
            console.log(error)
            toast(error.response)
        }
    }


  return (
    <>
        <Header title={'Users Management'} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Users'} label={'Search Users'} />
                <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
            </div>
        {/* <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
        {(filters.cityId || filters.routeId || filters.status) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
        </div> */}
        </div>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className='table-fixed flex-1'>
            <colgroup>
                <col style={{width:'40%'}}/>
                <col style={{width:'40%'}}/>
                <col style={{width:'40%'}}/>
                <col style={{width:'40%'}}/>
                <col style={{width:'40%'}}/>
                <col />
                <col />
            </colgroup>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr >
                    <th><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Name {sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></th>
                    <th><p onClick={()=>onSortPress('email')} className='cursor-pointer flex items-center hover:text-black'>Email{sort === 'email' || sort === '-email'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></th>
                    <th><p onClick={()=>onSortPress('role')} className='cursor-pointer flex items-center hover:text-black'>Role{sort === 'role' || sort === '-role'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></th>
                    <th><p onClick={()=>onSortPress('active')} className='cursor-pointer flex items-center hover:text-black'>Status{sort === 'active' || sort === '-active'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reps.map((item,index)=>
                        {
                            return <tr onClick={()=>navigate(`/users/${item.id}`)}>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.name}</p>
                                    </div>
                                </td>
                                <td className='overflow-hidden '>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.email}</p>
                                    </div>
                                </td>
                                <td className='capitalize overflow-hidden '>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.role}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className={`text-[13px] font-medium px-3 inline-block py-1 rounded-md ${item.active ===  true ? 'bg-[#39C7A5] bg-opacity-50 text-green-700 ' : 'text-red-700 bg-red-200 bg-opacity-10'}}`}>{item.active ===  true ? 'Active' : 'Inactive'}</p>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showManage.status ? <ManageUser submitting={submitting} onClose={setShowManage} onSubmit={onSubmit} edit={showManage.edit}/> : null}
        { showFilters ? <RepFilter onSubmit={onFilterSubmit} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
    </>
  )
}
