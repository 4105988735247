import { useEffect, useState } from "react"

import SmallPopup from "../../../components/SmallPopup"
import Input from "../../../components/Input";

export default function InitiateProduct({onClose,edit=false,onSubmit,data,id,submitting})
{
    const [manageData,setManageData] = useState()
    const [activeItem,setActiveItem] = useState(null)
    const formName = 'editRetailer';
    const [weightUsed,setWeightUsed] = useState('')

    useEffect(()=>{
        const item = data?.productionProducts?.find(product => product.id === id);
        setActiveItem(item);
    },[]) 

    const onSelectBlend = (e) => {
        setManageData(data?.productionBlends?.find(item => item?.blendUsed?.id === e.target.value))
    }

    return <><SmallPopup title={'Initiate Product Manufacturing' } submitting={submitting} submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,id,{weightUsed,productionBlendId:manageData?.id})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div className="mb-4 col-span-2">
                <label>Select Blend*</label>
                <select className="w-full" value={manageData?.blendUsed?.id} onChange={onSelectBlend}>
                    <option value={''}>Select Blend</option>
                    {data?.productionBlends?.map((item,index)=>
                    <option value={item?.blendUsed?.id}>{item?.blendUsed?.batchNumber} - {item?.blendUsed?.blendName}</option>
                    )}
                </select>
            </div>
            <div>
                <label>Enter Quantity</label>
                <Input min={parseInt((activeItem?.product?.weight) / 1000 * activeItem?.quantity).toFixed(2)} type="number" className="w-full" value={weightUsed} setValue={(value)=>setWeightUsed(value)}/>
                <p className="text-xs font-medium ">{activeItem?.product?.weight ? `${(activeItem?.product?.weight) / 1000 * activeItem?.quantity} Kg` : null}</p>
            </div>
            {/* <div>
                <label>Required Quantity</label>
                <p className="text-sm font-medium ">{activeItem?.product?.weight ? `${(activeItem?.product?.weight) / 1000 * activeItem?.quantity} Kg` : null}</p>
            </div> */}
            <div>
                <label>Blend Remaining</label>
                <p className="text-sm font-medium ">{manageData?.weightUsed ? `${manageData?.weightUsed} Kg` : null}</p>
            </div>
        </form>
    </SmallPopup>
    </>
}