import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { COUNTRIES, RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"

export default function ManageWarehouse({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState(!edit ? {name:'',type:''} : {...updateData,name:updateData.name,type:updateData.type})
    const formName = 'manageGarden';



    return <><Popup size="sm" title={edit ? 'Update Garden' : 'Create Garden'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='w-full'>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="col-span-2">
                <label>Warehouse Name</label>
                <Input type='text' placeholder={'Enter Warehouse Name'} value={manageData.name} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Warehouse Type</label>
                <Select required={true} options={[{name:'Raw',value:'raw'},{name:'Blended',value:'blend'}]}  value={manageData.type} setValue={(value)=>setManageData(data=>({...data,type:value}))}/>
            </div>
            </div>
            </div>
        </form>
    </Popup>
    </>
}