import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageProduct from './components/ManageProduct'
import { base64ToBlob, dataURItoBlob } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import { LIMIT } from '../../helpers/constants'
import AdjustStock from './components/AdjustStock'
import ModalImage from 'react-modal-image'
import StockFilter from './components/StockFilter'
import ManageCommonBom from './components/ManageCommonBom'

export default function CommonBom() {

    const [searchText,setSearchText] = useState('')
    const [products,setProducts] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [showUpdate,setShowUpdate] = useState(false)

    const navigate = useNavigate()
    const [sort,setSort] = useState('stock');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)
    const [filters,setFilters] = useState({buyerId: null})
    const [showFilters,setShowFilters] = useState(false)
    

    // const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Add Common BOM</button>
        </div>
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getCommonBoms(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&sort=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                if(filters.buyerId) query+= `&buyerId=${filters.buyerId.id}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/common-bom?populate=true${query}`)
                setProducts(res.data.rows)
                setCount(res.data.count)
        } catch (error) {
            ErrorToast('Error getting Stocks')
        }
    }
    useEffect(()=>
    {
        getCommonBoms();
    },[sort,searchText,offset,filters])



    const onSubmit = async(e,data,croppedImage)=>
        {
            try 
            {
                e.preventDefault()
                let imageRes;
                if(croppedImage)
                {
                    let formData = new FormData();
                    formData.append('file',croppedImage)
                    imageRes = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/upload`,formData,{headers: {
                'content-type': 'multipart/form-data'}})
                } 

                if(showCreate.edit)
                {
                    let updateData = croppedImage ? {...data,image:imageRes.data} : {...data};
                // let base64Image = await getBase64Image(data.retailerShopImage)
                let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/common-bom/${data.id}`,updateData) 
                await getCommonBoms()
                InfoToast('Common BOM Updated')
                setShowCreate({edit:null,status:false})
                }
                else
                {
                    let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/common-bom`,data) 
                    await getCommonBoms()
                    InfoToast('Common BOM Added')
                    setShowCreate({edit:null,status:false})
                }
            } catch (error) {
                console.log('error',error)
                ErrorToast('Error Updating Image')
            }
        }

    const onFilterSubmit = (filters)=>
        {
            try 
            {
                console.log('filters',filters)
                setFilters({...filters})
                setCount(5)
                setOffset(0)
                setShowFilters(false)
            } catch (error) {
                toast('Order Filtered')
            }
        }
    
        const clearFilter = ()=>
        {
            try 
            {
                setFilters({buyerId:null})
                setShowFilters(false)
            } catch (error) {
                toast('Order Filtered')
            }
        }


  return (
    <>
        <Header title={'Common BOM Stock Management'} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white w-full'>
        <div className='flex justify-between w-full'>
            <div className='flex '>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search BOM'} label={'Search BOM'} />
            <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
                            </div>
                {/* <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.buyerId) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div> */}

                </div>
        </div>
        <div className='flex flex-1 bg-white w-full overflow-scroll'>
            <table className='table-fixed w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>BOM Image</p></td>
                    <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>BOM Name{sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('stock')} className='cursor-pointer flex items-center hover:text-black'>Stock{sort === 'stock' || sort === '-stock'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('rate')} className='cursor-pointer flex items-center hover:text-black'>Rate{sort === 'rate' || sort === '-rate'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        products.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize w-[200px]'>
                                <div className="image-preview mr-2 ">
                                    <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt={item.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex items-center align-middle'>
                                    <div>
                                    </div>
                                        <div>
                                            <p className='text-sm font-medium my-0'>{item.name}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex items-center align-middle'>
                                    <div>
                                    </div>
                                        <div>
                                            <p className='text-sm font-medium my-0'>{item.stock}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex items-center align-middle'>
                                    <div>
                                    </div>
                                        <div>
                                            <p className='text-sm font-medium my-0'>Rs.{item.rate}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowCreate({edit:true,status:item})}>Edit Bom</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showCreate.status ? <ManageCommonBom onClose={setShowCreate} updateData={showCreate.status} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
    </>
  )
}

