import { useEffect, useState } from "react"
import SmallPopup from "../../../components/SmallPopup"
import Input from "../../../components/Input"
import { ErrorToast } from "../../../helpers/toasters"
import { authAxios } from "../../../helpers/axios"
import Select from "../../../components/Select"

export default function CompleteBlend({setShow,onSubmit,edit=false})
{
    const [grade,setGrade] = useState({id:null,blendWeight:'',warehouseId:null})
    const [warehouses,setWarehouses] = useState([])

    async function getWarehouses()
    {
        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/warehouse?type=blend`)
            setWarehouses(res.data)
        } catch (error) {
            console.log(error)
            ErrorToast(error.response.data.message)
        }
    }
    useEffect(()=>{
        getWarehouses()
    },[])
    return <SmallPopup onClose={()=>setShow(false)}  title={'Complete Blend'} submitTitle={'Complete'} formName={'completeBlend'}>
        <form className='w-full' onSubmit={(e)=>onSubmit(e,grade.blendWeight,grade.warehouseId)} id="completeBlend">
            <div>
                <label>Blend Weight (Final)</label>
                <Input placeholder={'Enter Blend Weight'} type='number' value={grade.blendWeight} setValue={(value)=>setGrade(grade=>({...grade,blendWeight:value}))} required={true}/>
            </div>
            <div>
                <label>Warehouse</label>
                <Select options={warehouses} required={true} customValue={'id'} customLabel={'name'} value={grade.warehouseId} setValue={(value)=>setGrade(grade=>({...grade,warehouseId:value}))}/>
            </div>
        </form>
    </SmallPopup>
}
    