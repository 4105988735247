import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageProduct from './components/ManageProduct'
import { base64ToBlob, dataURItoBlob, getDateFormat, getTimeFormat } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import { LIMIT,BRAND_COLOR } from '../../helpers/constants'
import AdjustStock from './components/AdjustStock'
import ModalImage from 'react-modal-image'
import StockFilter from './components/StockFilter'
import ManageCommonBom from './components/ManageCommonBom'

export default function Updates() {

    const [type,setType] = useState('manufacturing')
    const [updates,setUpdates] = useState([])
    const [searchText,setSearchText] = useState('')
    const [offset,setOffset] = useState(0)
    const [count,setCount] = useState(0)
    const [sort,setSort] = useState('')
    const [filters,setFilters] = useState({})
    const [loading,setLoading] = useState(false)
    const [onSortPress,setOnSortPress] = useState('createdAt')

  useEffect(()=>{
    async function getManufacturingUpdates(){
      try{
        let query = `limit=${LIMIT}&offset=${offset}`
        if (searchText) query += `&search=${searchText}`
        let response = null
        if(type === 'manufacturing')
        {
            response = await authAxios.get(`/production/update?${query}`)
            setUpdates(response.data.rows)
            setCount(response.data.counts)
        }
        else if(type === 'purchase')
            {
                response = await authAxios.get(`/purchase/receiving?${query}`)
                setUpdates(response.data.rows)
                setCount(response.data.totalCount)
        } 
        else if(type === 'shipping')
        {
            response = await authAxios.get(`/shipping/update?${query}`)
            setUpdates(response.data.rows)
            setCount(response.data.totalCount)
        }
      }catch(error){
        ErrorToast(error.response.data.message)
      }
    }
    getManufacturingUpdates()
  },[offset,searchText,type])

  const RightContent = () => {
    return <div className='flex items-center bg-[#efefef] p-1 rounded-md'>
        <div className='flex items-center gap-2'>
            <p className={`btn-xs cursor-pointer border-1 border-gray-200 rounded-r-none mr-0  rounded-md text-black ${type !== 'manufacturing' ? 'bg-[#fff]' : `bg-[${BRAND_COLOR}] text-white`}`} onClick={()=>setType('manufacturing')}>Manufacturing</p>
        </div>
        <div className='flex items-center gap-2'>
            <p className={`btn-xs cursor-pointer border-1 border-gray-200 rounded-none ml-0 mr-0  text-black ${type !== 'purchase' ? 'bg-[#fff]' : `bg-[${BRAND_COLOR}] text-white`}`} onClick={()=>setType('purchase')}>Purchase</p>
        </div>
        <div className='flex items-center gap-2'>
            <p className={`btn-xs cursor-pointer border-gray-200 rounded-l-none ml-0 mr-0  rounded-md text-black ${type !== 'shipping' ? 'bg-[#fff]' : `bg-[${BRAND_COLOR}] text-white`}`} onClick={()=>setType('shipping')}>Shipping</p>
        </div>
    </div>
  }

  return (
    <>
        <Header title={'All Updates'} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white w-full'>
        <div className='flex justify-between w-full'>
            <div className='flex '>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Updates'} label={'Search Updates'} />
            <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
                            </div>
                {/* <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.buyerId) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div> */}

                </div>
        </div>
        <div className='flex flex-1 bg-white w-full overflow-scroll'>
            <table className='table-fixed w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Product Image</p></td>


                    {(type === 'manufacturing' || type === 'shipping') && <td><p onClick={()=>onSortPress('buyerName')} className='cursor-pointer flex items-center hover:text-black'>Product/Buyer{sort === 'buyerName' || sort === '-buyerName'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>}

                    {(type === 'purchase') && <td><p onClick={()=>onSortPress('supplierName')} className='cursor-pointer flex items-center hover:text-black'>Supplier{sort === 'supplierName' || sort === '-supplierName'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>}


                    <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Date/Time{sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>


                    <td><p onClick={()=>onSortPress('id')} className='cursor-pointer flex items-center hover:text-black'>{type === 'manufacturing' ? 'Sales Order ID' : type === 'purchase' ? 'Purchase Order ID' : 'Shipping Order ID'}{sort === 'id' || sort === '-id'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>


                    <td><p onClick={()=>onSortPress('quantity')} className='cursor-pointer flex items-center hover:text-black'>Quantity{sort === 'quantity' || sort === '-quantity'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                {/* <td><p>Action</p></td> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        updates.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize w-[200px]'>
                                <div className="image-preview mr-2 ">
                                    <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${type === 'manufacturing' ? item.product?.image : type === 'purchase' ? item.commonBom?.image : item.shippingProduct?.product?.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${type === 'manufacturing' ? item.product?.image : type === 'purchase' ? item.commonBom?.image : item.shippingProduct?.product?.image}`} alt={type === 'manufacturing' ? item.product?.name : type === 'purchase' ? item.commonBom?.product?.name : item.shippingProduct?.product?.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex items-center align-middle'>
                                    <div>
                                    </div>
                                        {(type === 'manufacturing') && <div>
                                            <p className='text-sm font-medium my-0'>{item.product?.name}</p>
                                            <p className='text-xs font-medium my-0 text-[#808080]'>{item.productionOrder?.salesOrder?.buyer?.name}</p>
                                        </div>}
                                        {(type === 'shipping') && <div>
                                            <p className='text-sm font-medium my-0'>{item.shippingProduct?.product?.name}</p>
                                            <p className='text-xs font-medium my-0 text-[#808080]'>{item.shippingOrder?.salesOrder?.buyer?.name}</p>
                                        </div>}
                                        {(type === 'purchase') && <div>
                                            <p className='text-sm font-medium my-0'>{item.commonBomId ? item.commonBom?.name : item.bom?.name}</p>
                                            <p className='text-xs font-medium my-0 text-[#808080]'>{item.purchasematerial?.supplier?.name}</p>
                                        </div>}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex items-center align-middle'>
                                    <div>
                                    </div>
                                        <div>
                                            <p className='text-sm font-medium my-0'>{getDateFormat(item.createdAt)}</p>
                                            <p className='text-xs font-medium my-0 text-[#808080]'>{getTimeFormat(item.createdAt)}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex items-center align-middle'>
                                    <div>
                                    </div>
                                        <div>
                                            <p className='text-sm font-medium my-0'>{type === 'manufacturing' ? item.productionOrder?.salesOrder?.orderId : type === 'purchase' ? item.purchase?.orderId : item.shippingOrder?.orderId}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <p className='text-sm font-medium my-0'>{type === 'manufacturing' ? item.quantity : type === 'purchase' ? item.receivedQuantity : item.quantity}</p>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
  )
}

