import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { getDateFormat, getTimeFormat, getValidDateFormat } from '../../helpers/utils'
import Loader from '../../components/Loader'
import TabGroup from '../../components/TabGroup'
import OrderFullInfo from './components/OrderFullInfo'
import ProductionInfo from './components/ProductionInfo'
import PurchaseInfo from './components/PurchaseInfo'
import EditOrder from './components/EditOrder'
import DeleteOrder from './components/DeleteOrder'

export default function OrderInfo() {

    const {id} = useParams();
    const [orders,setOrders] = useState([])
    const [orderInfo,setOrderInfo] = useState({})
    const [showEdit,setShowEdit] = useState(false)
    const [activeTab,setActiveTab] = useState('order-info')
    const [loading,setLoading] = useState(true)
    const [showDelete,setShowDelete] = useState(false)
    const [deleting,setDeleting] = useState(false)
    const navigate = useNavigate()

    const RightContent = ()=>
    {
        return <div>
          { orderInfo?.purchaseOrder === null && orderInfo?.productionOrder?.status === 'pending' ? <button type='button' className='btn-md bg-red-500 mr-4 hover:bg-red-600' onClick={()=>setShowDelete(true)}>Delete Order</button> : null}
          <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Edit Sales Order</button>
        </div>
    }

    async function getOrderInfo(){

        try 
        {
            let ordersRes = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/order/${id}`)
            if(ordersRes.data) setOrderInfo(ordersRes.data)
                setLoading(false)
            } catch (error) {
            setLoading(false)
            ErrorToast('Error getting Orders')
        }
    }
    useEffect(()=>
    {
        getOrderInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/order/${id}`,data); 
            if(res.data)
            {
                await getOrderInfo()
                setShowEdit(false)
                InfoToast('Order Updated')
            }
            else InfoToast('error updating order')
        } catch (error) {
            console.log(error)
            InfoToast(error.response)
        }
    }

    const onDelete = async(e)=>
    {
        try 
        {
            e.preventDefault()  
            setDeleting(true)
            let res = await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/order/${id}`); 
                await getOrderInfo()
                setShowDelete(false)
                InfoToast('Order Deleted')
            navigate('/sales')
            setDeleting(false)
        } catch (error) {
            setDeleting(false)
            ErrorToast(error.response.data.error)
        }
    }

  return (
    !loading ? <>
        <Header title={`#${orderInfo?.orderId}`} parentLink='/sales' parentTitle='Sales Management' RightContent={RightContent}/>
        <div className=' flex justify-between  border-b border-[#e3e3e3] bg-white px-4'>
            <div>
               <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Order Info',value:'order-info'},{label:'Purchase Order Info',value:'purchase-info'},{label:'Production Info',value:'production-info'}]}/>
            </div>
            <div>

            </div>
        </div>
        <div className='flex flex-1 overflow-scroll bg-white w-full '>
            {activeTab === 'order-info' ? <OrderFullInfo orderInfo={orderInfo} id={id}/> : activeTab === 'purchase-info' ? <PurchaseInfo orderInfo={orderInfo.purchaseOrder} id={id}/> : <ProductionInfo orderInfo={orderInfo.productionOrder}/>}
            
        </div>

        { showEdit ? <EditOrder onClose={setShowEdit} onSubmit={onSubmit} edit={true} updateData={orderInfo}/> : null}
        { showDelete ? <DeleteOrder onClose={setShowDelete} onSubmit={onDelete} id={id} submitting={deleting}/> : null}
    </> : <Loader/>
  )
}


