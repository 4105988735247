import { useState } from "react"
import SmallPopup from "../../../components/SmallPopup"

export default function DeleteProduct({onClose,edit=false,onSubmit,id})
{
    const formName = 'deleteProduct';

    return <><SmallPopup title={'Delete Product' } submitTitle={'Delete Product'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,id)} id={formName} className='w-full'>
            <div className="mb-4">
                <p className="text-sm font-medium ">Are you sure you want to delete the product?</p>
            </div>
        </form>
    </SmallPopup>
    </>
}