import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { COUNTRIES, RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"

export default function ManageWarehouseSection({onClose,edit=false,onSubmit,updateData,warehouse})
{
    const [manageData,setManageData] = useState(!edit ? {name:'',warehouse:warehouse} : {...updateData,name:updateData.name,warehouse:updateData.warehouse})
    const formName = 'manageSection ';



    return <><Popup size="sm" title={edit ? 'Update Warehouse Section' : 'Create Warehouse Section'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='w-full'>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="col-span-2 mb-4">
                <label>Warehouse Name</label>
                <p className="text-sm font-medium">{manageData.warehouse.name}</p>
            </div>
            <div className="col-span-2">
                <label>Section Name</label>
                <Input type='text' placeholder={'Enter Section Name'} value={manageData.name} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            </div>
            </div>
        </form>
    </Popup>
    </>
}