import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import Input from '../../../components/Input'
import Popup from '../../../components/Popup'
import SmallPopup from '../../../components/SmallPopup'

export default function Cities({setShowManage,showManage}) {

    const [cities,setCities] = useState([])
    const [showDeleteCity,setShowCityDelete] = useState(false)
    const [deleting,setDeleting] = useState(false)
    const [submitting,setSubmitting] = useState(false)

    async function getCities(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            if(res.data)
            {
                setCities(res.data)
            }
        } catch (error) {
            console.log(error)
            toast('Error getting orders')
        }
    } 

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res;
            if(showManage.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/city/${data.id}`,{...data})  
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/city`,{...data}) 
                
            }
            if(res.data)
            {
                if(showManage.edit)
                {
                    setCities(cities=>{
                        let prev = [...cities]
                        let itemIndex = prev.findIndex(item=>item.id === showManage.edit)
                        prev[itemIndex] = {...prev[itemIndex],name:res.data.name}
                        return prev;
                    })
                }
                else setCities(cities=>([...cities,{...res.data}]))
                InfoToast(showManage.edit ? 'City Updated' : 'City Created',{position: toast.POSITION.BOTTOM_CENTER,hideProgressBar:true})
                setShowManage({type:null,status:false,edit:null})
            }
            else toast('error creating/updating city')
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            console.log('error',error)
            ErrorToast(error.response.data.error.name)
        }
    }


    const onCityDelete = async(e)=>
    {
        try 
        {
            e.preventDefault()
            console.log('showDeleteCity',showDeleteCity)
            setDeleting(true)
            await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/city/${showDeleteCity}`) 
            getCities()
            setShowCityDelete(false)
            setDeleting(false)
            InfoToast('City Deleted')
        } catch (error) {
            console.log('error',error)
            setDeleting(false)
            ErrorToast('City Deletion Failed')
            
        }
    }



    useEffect(()=>
    {
        getCities();
    },[])


  return (
    <div className='flex flex-col w-full'>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>City Name</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        cities.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.name}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowManage({type:'cities',status:true,edit:item.id})}>Edit</button>
                                    <button className='btn-xs btn-red ml-1' onClick={()=>setShowCityDelete(item.id)}>Delete</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showManage.status && showManage.type ==='cities' ? <ManageCity setShow={setShowManage} onSubmit={onSubmit} edit={showManage.edit} /> : null}
        {showDeleteCity ? <DeleteCity submitting={deleting} onSubmit={onCityDelete} onClose={setShowCityDelete}/> : null}
    </div>
  )
}

const DeleteCity = ({onSubmit,onClose,submitting})=>
{
    return <SmallPopup formName={'deleteCity'} submitting={submitting} title={'Delete City'} onClose={onClose} onSubmit={onSubmit} submitTitle={'Yes, Delete'}>
        <form className='w-full' onSubmit={(e)=>onSubmit(e)} id="deleteCity">
        <div>
            <p className="text-sm">Are you sure you want to delete the city?</p>
        </div>
        </form>
    </SmallPopup>
}



const ManageCity = ({setShow,onSubmit,edit=false})=>
{
    const [city,setCity] = useState({id:null,name:''})
    const [loading,setLoading] = useState(edit ? true : false)
    useEffect(()=>
    {
        async function getCityInfo(){
            if(edit)
            {
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city/${edit}`)
                setCity({id:res.data.id,name:res.data.name})
                setLoading(false)
            }
        }
        getCityInfo()
    },[])
    return loading ? 'loading' :<SmallPopup onClose={()=>setShow({type:null,status:false})}  title={edit ?  'Edit City' : 'Add City'} submitTitle={edit ? 'Update' : 'Add'} formName={'createCity'}>
        <form className='w-full' onSubmit={(e)=>onSubmit(e,edit ? city : {name:city.name})} id="createCity">
            <div>
                <label>City Name</label>
                <Input placeholder={'Enter City Name'} value={city.name} setValue={(value)=>setCity(city=>({...city,name:value}))} required={true}/>
            </div>
        </form>
    </SmallPopup>
}
