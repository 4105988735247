import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import SmallPopup from "../../../components/SmallPopup"

export default function UpdateProductionReceiving({data,onClose,edit=false,onSubmit,submitting})
{
    const [manageData,setManageData] = useState({id:edit.id,productId:edit.product.id,finishedCartons:''})
    const [selectedBlend,setSelectedBlend] = useState(null)
    const formName = 'updateReceiving';

    const onSelectBlend = (e) => {
        console.log('e',data?.productionBlends?.find(item => item?.blendUsed?.id === e.target.value))
        setManageData(prevData => ({...prevData, productionBlendId: data?.productionBlends?.find(item => item?.blendUsed?.id === e.target.value).id}))
        setSelectedBlend(data?.productionBlends?.find(item => item?.blendUsed?.id === e.target.value).blendUsed.id)
    }

    useEffect(()=>{
        console.log('manageData',manageData)
    },[manageData])

    return <><SmallPopup title={'Update Manufactured Quantity' } submitting={submitting} submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div className="mb-4">
                <label>Product Name*</label>
                <p className="text-sm font-medium ">{edit.product.name}</p>
            </div>
            <div>
                <label>Manufactured Cartons</label>
                <Input type='text' placeholder={'Enter Cartons'} required={true} value={manageData.finishedCartons} setValue={(value)=>setManageData(data=>({...data,finishedCartons:value}))}/>
                <p className="text-xs font-medium p-2 ">{manageData?.finishedCartons ? `${(manageData?.finishedCartons * edit.product.quantityPerCarton) * edit.product.weight /1000} Kg` : null}</p>
            </div>
            <div className="mb-4 col-span-2">
                <label>Select Blend*</label>
                <select className="w-full" value={manageData?.blendUsed?.id} onChange={onSelectBlend}>
                    <option value={''}>Select Blend</option>
                    {data?.productionBlends?.map((item,index)=>
                    <option value={item?.blendUsed?.id}>{item?.blendUsed?.batchNumber} - {item?.blendUsed?.blendName}</option>
                    )}
                </select>
                <p className="text-xs font-medium p-2 ">{manageData?.blendUsed?.weightUsed ? `${manageData?.blendUsed?.weightUsed} Kg` : null}</p>
            </div>
        </form>
    </SmallPopup>
    </>
}