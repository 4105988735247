import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { COUNTRIES, RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"

export default function ManageGarden({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState(!edit ? {name:'',contactNumber:'',contactPerson:'',contactEmail:'',address:'',status:1} : {...updateData,name:updateData.name,contactNumber:updateData.contactNumber,contactPerson:updateData.contactPerson,contactEmail:updateData.contactEmail,status:updateData.status})
    const formName = 'manageGarden';



    return <><Popup size="sm" title={edit ? 'Update Garden' : 'Create Garden'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='w-full'>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-4 gap-x-3 gap-y-0 w-full">
            <div className="col-span-2">
                <label>Garden Name</label>
                <Input type='text' placeholder={'Enter Garden Name'} value={manageData.name} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Contact Person</label>
                <Input type='text' placeholder={'Enter Contact Person'} value={manageData.contactPerson} setValue={(value)=>setManageData(data=>({...data,contactPerson:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Phone Number (+91)</label>
                <Input type='text' placeholder={'Enter Contact Number'} value={manageData.contactNumber} setValue={(value)=>setManageData(data=>({...data,contactNumber:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Status</label>
                <Select placeholder={'Select Status'} options={[{name:'Active',value:1},{name:'Inactive',value:0}]}  value={manageData.status} setValue={(value)=>setManageData(data=>({...data,status:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Address</label>
                <textarea className="h-[40px] w-full text-input mb-4 font-medium leading-normal" placeholder={'Enter Address'} value={manageData.address} onChange={(e)=>setManageData(data=>({...data,address:e.target.value}))}/>
            </div>
            </div>
            </div>
        </form>
    </Popup>
    </>
}