import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"
import { data } from "../../dashboard/components/PriceData"
import SmallPopup from "../../../components/SmallPopup"

export default function UpdateReceiving({onClose,edit=false,onSubmit,updateData,submitting})
{
    const [manageData,setManageData] = useState({id:edit.id,bomId:edit.commonBom ? edit.commonBom.id : edit.bom ? edit.bom.id : edit.productCommonBom.commonBom.id,receivedQuantity:'',type:edit.bom ? 'bom' : 'commonBom' })
    const [category,setCategory] = useState([])
    const [hsn,setHsn] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';

    return <><SmallPopup submitting={submitting} title={'Update Receivings' } submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div className="mb-4">
                <label>Purchase Material Name*</label>
                <p className="text-sm font-medium ">{edit.commonBom ? edit.commonBom.name : edit.bom ? edit.bom.name : edit.productCommonBom.commonBom.name}</p>
            </div>
            {edit.commonBom ? null : <div className="mb-4">
                <label>Product Name*</label>
                <p className="text-sm font-medium ">{edit.commonBom ? edit.commonBom.name : edit.bom ? edit.bom.product.name : edit.productCommonBom.product.name}</p>
            </div>}
            <div>
                <label>Received Quantity</label>
                <Input type='text' placeholder={'Enter Quantity'} required={true} value={manageData.receivedQuantity} setValue={(value)=>setManageData(data=>({...data,receivedQuantity:value}))}/>
            </div>
        </form>
    </SmallPopup>
    </>
}