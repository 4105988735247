import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name:'auth',
    initialState:{
        isLoggedIn:false,
        token:false,
        userName:null,
        email:null,
        showImagePopup:null,
        role:null
    },
    reducers:{
        login:(state,action)=>
        {
            console.log('action.payload',action.payload)
            return{
                ...state,
                isLoggedIn:true,
                token:action.payload.token,
                // refreshToken:action.payload.remember_token,
                userName:action.payload.userName,
                email:action.payload.email,
                role:action.payload.role,
              }
        },
        updateUserRole:(state,action)=>
        {
            return{
                ...state,
                role:action.role,
              }
        },
        logout:(state,action)=>
        {
            return{
                isLoggedIn:false,
                token:null,
                userName:null,
                email:null,
                role:null,
              }
        },
        imagePopupUpdate:(state,action)=>
        {
            return{
                showImagePopup:action.image ? action.image : null,
              }
        }
    }
})

export const {login,logout,updateUserRole,imagePopupUpdate} = authSlice.actions

export default authSlice.reducer